import WrapperLemma from "./WrapperLemma";
import { useLemma } from "../../hooks/useLemma";
import { motion } from "framer-motion";
import styles from "../../styles/WrapperWord.module.css";
import { MdMenuBook } from "react-icons/md";


export default function WrapperWord(props) {

    const { word, features, type } = props; // Destructure props for cleaner code

    // Function to render features inline
    const renderFeatures = () => {
        return Object.keys(features)
            .filter((featureKey) => featureKey !== 'significato' && featureKey !== 'pos' && featureKey !== 'lemma') // Exclude "significato"
            .map((featureKey) => {
            const featureValue = features[featureKey];
            return featureKey === 'persona' ? `${featureValue} ${featureKey}` : featureValue;
        }).join(', ');
    };

    const hasAdditionalFeatures = Object.keys(features).length > 0; // Check if there are features

    //const { lemmas, loading: lemmaLoading, error } = useLemma([word], type);

    return (
        <motion.div
      layout
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opactiy: 0 }}
    >
        <div className={`${styles.wordBox} text-white mb-3 radius-teseo`}>
            <span>
            <span className="fontXL inter-bold"><strong>{word}</strong></span>   <span className="fontL mx-1">{features.pos}</span>
            </span>
            <br />

            {hasAdditionalFeatures && (
                <span>
                    {renderFeatures()}
                </span>
            )}
            {hasAdditionalFeatures && <br />}

            {/* Third line for the "significato" feature */}
            {features.significato && <span>{features.significato}</span>}

            <div>
                <br></br>
                <WrapperLemma type={type} lemma={features.lemma}></WrapperLemma>
            </div>
        </div>
        </motion.div>
    );
}
