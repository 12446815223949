import React from 'react';
import styles from '../styles/PaymentTeseo.module.css';
import { FaRegCircleCheck } from "react-icons/fa6";
import { Button } from '../components/ui/button';

const SubscriptionCard = (props) => {
  const { activeTab, handleTab, disabledPay, goToCheckout, dataReady, user } = props;

  const features = [
    {
      text: 'Fino a 25 versioni di latino e greco tradotte e analizzate ogni mese. ',
    },
    {
      text: 'Scatta & Traduci: Carica una foto della tua versione per copiarne il testo su Teseo ',
    },
    {
      text: 'Funzione dizionario: Scopri come cercare qualsiasi parola della versione',
    },
    {
      text: `Tutte le prossime novità: Teseo è solo all'inizio del labirinto`,
    }
  ];

  return (
    <div className={`${styles.boxTeseo} background-teseo-component radius-teseo p-10 w-full`}>
      <div className="space-y-4">
        <h2 className="fontXXL bold">Teseo+</h2>
        <div className=''>
        <button
              onClick={() => handleTab('annuale')}
              className={`hover:text-white focus:outline-none px-4 py-1 ${
                  activeTab === 'annuale' ? 'text-white border-b-2 border-white' : ''
              }`}
          >
            <span className='regular fontS'>ANNUALE</span>
          </button>
          <button
              onClick={() => handleTab('mensile')}
              className={`hover:text-white focus:outline-none px-4 py-1 ${
                  activeTab === 'mensile' ? 'text-white border-b-2 border-white' : ''
              }`}
          >
            <span className='regular fontS'>MENSILE</span>
          </button>
        </div>
      </div>

      <div className="">
        <div>
          {activeTab === 'annuale' && (
            <div className='py-3'>
              <span className='fontXL bold'>30€</span>
              <span className='fontL regular'> /anno*</span>
            </div> 
          )}
          
          {activeTab === 'mensile' && (
              <div className='py-3'>
              <span className='fontXL bold'>2.99€</span>
              <span className='fontL regular'> /mese</span>
            </div> 
          )}
        </div>

        <div className='d-flex'>
            <ul className="space-y-3">
            {features.map((feature, index) => {
                return (
                <li key={index} className="gap-2 d-flex align-items-center">
                    <FaRegCircleCheck fontSize={20} className={`opacity-70`} />
                    <span className='regular fontM'>{feature.text}</span>
                </li>
                );
            })}
            </ul>
        </div>
        
      </div>

      <div className='pt-4'>
        <Button className="w-full bg-[#BB0A0B] hover:opacity-90 white fontL bold py-3" onClick={goToCheckout} disabled={disabledPay}>
        {(disabledPay || (user && !dataReady)) ? `Scelta giusta...` : 'Sblocca Teseo+'}
        </Button>
      </div>
      
    </div>
  );
};

export default SubscriptionCard;