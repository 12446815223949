// PageSwitch.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Switch = ({disabled}) => {
  const location = useLocation(); // Current URL
  const navigate = useNavigate(); // For programmatically navigating

  // Check if current path is /greco
  const isGreco = location.pathname === "/greco";
  const isLatino = location.pathname === "/";

  const togglePageLatino = () => {
    if(disabled) {return;}
    if(isGreco) {
      navigate("/");
    }
  }

  const togglePageGreco = () => {
    if(disabled) {return;}
    if(isLatino) {
      navigate("/greco");
    }
  }

  return (
    <div className="d-flex gap-2 background-teseo-component radius-teseo text-center px-2 py-2 fontS mt-3">
      <span onClick={togglePageLatino} className={`${isLatino && 'bg-white text-black radius-teseo'} text-center px-10 py-1 transition-all ease cursor-pointer`}>
        LATINO
      </span>
      <span onClick={togglePageGreco} className={`${isGreco && 'bg-white text-black radius-teseo'} text-center px-10 py-1 transition-all ease cursor-pointer`}>
        GRECO
      </span>
      {/* <label className={styles.switch}>
        <input
          type="checkbox"
          checked={isGreco} // Check if we're on the Greco page
          onChange={togglePage} // Toggle between pages
          disabled={disabled}
        />
        <span className={`${styles.slider} ${isGreco ? styles.grecoActive : styles.latinoActive}`}>
          <span className={`${styles.switchText} fontM regular`}>
            {isGreco ? 'Greco' : 'Latino'}
          </span>
        </span>
      </label> */}
    </div>
  );
};

export default Switch;
