import { useEffect, useState } from "react";
import { Container, Alert, Form, Button } from "react-bootstrap";
import { useUserAuth } from "./UserAuthContext";
import api from '../API/backend';
import styles from "../styles/PopupTeseo.module.css";
import { IoMdClose } from "react-icons/io";

const API_URL = process.env.REACT_APP_BACKEND_PATH;


const PopupTerms = ({closePopup}) => {
    const { user, fetchUserData } = useUserAuth();

    const [error, setError] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [dataConsent, setDataConsent] = useState(false);

    const handleSubmitAcceptedTerms = async(e) => {
        e.preventDefault();
        setError("");
        if (acceptTerms && dataConsent) {
            try {
                let user_uid = user.uid;
                const response = await api.post(`${API_URL}/user/terms-accepted`);
                if (response.status === 200) {
                    fetchUserData();
                    localStorage.clear();
                    closePopup();
                } else {
                    console.log("C'è un errore, contattaci per favore.");
                    throw new Error("Errore nella registrazione.");
                }
            } catch (err) {
                setError("Teseo ti chiede di fare logout e login, oppure contattaci.");
            }
        } else {
            setError("Accetta i termini per continuare");
        }
    };

    return (
        <Container className="pt-20">
            <div className={`${styles.popup_container} d-flex flex-column justify-content-center align-items-center background-teseo-component radius-teseo`}>
                <div className="position-absolute right-10 top-5 cursor-pointer" onClick={closePopup}><IoMdClose fontSize={20}></IoMdClose></div>
                <div className="p-5">
                    <p className="bold text-center fontXL">Entra nella community</p>
                    <p className="fontS text-center regular">Accetta per poter continuare a combattere con Teseo</p>
                                {error && <Alert variant="danger" className="fontS my-3">{error}</Alert>}
                                <Form onSubmit={handleSubmitAcceptedTerms}>
                                    <Form.Group className="mt-4" controlId="formBasicCheckbox1">
                                        <Form.Check
                                            type="checkbox"
                                            onChange={(e) => setAcceptTerms(e.target.checked)}
                                            label={
                                                <span className="regular">
                                                    Accetto i{' '}
                                                    <a
                                                        href="https://www.iubenda.com/termini-e-condizioni/27118703"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className='underline'
                                                    >
                                                        Termini e Condizioni
                                                    </a>
                                                </span>
                                            }
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                                        <Form.Check
                                            type="checkbox"
                                            onChange={(e) => setDataConsent(e.target.checked)}
                                            label={
                                                <span className="regular">
                                                    Acconsento alla{' '}
                                                    <a
                                                        href="https://www.iubenda.com/privacy-policy/27118703"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className='underline'
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                </span>
                                            }
                                        />
                                    </Form.Group>
                                    <hr className="my-4"/>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" type="Submit" className="buttonTeseo bold fontL">
                                            Entra
                                        </Button>
                                    </div>
                                </Form>
                </div>
            
            </div>
        </Container>

    );
}

export default PopupTerms
