import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../API/backend';
const API_URL = process.env.REACT_APP_BACKEND_PATH;

export const useProcessVersion = ({
  user,
  inputText,
  userData,
  handleTranslateText,
  handleAnalyzeText,
  updateLimitInContext,
  trackEvent,
  setDisabled,
  setLoading,
  resetState,
  setError,
  previousTextRef
}) => {


  const [showVersionResults, setShowVersionResults] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [openPopupTerms, setOpenPopupTerms] = useState(false);

  const saveProcessedVersion = useCallback(async (inputText, lang, translationOutput, analysisOutput) => {
    try {
        await api.post(`${API_URL}/save`, {
            input: inputText,
            translation: translationOutput,
            analysis: analysisOutput,
            lang: lang,
        });
    } catch (error) {
        console.error('Error saving translation:', error);
    }
  }, []);


  const handleTranslate = useCallback(async (lang) => {
    try {
      const translationResult = await handleTranslateText(inputText, lang);
      
      if (translationResult) {
        trackEvent('Button', 'Click button translate latino', 'Translate latin text');

        return translationResult;
      }
    } catch (error) {
      console.error('Translation error:', error);
      throw error;
    }
  }, [inputText, handleTranslateText, trackEvent]);


  const handleAnalysis = useCallback(async (lang) => {
    try {
      const analysisResult = await handleAnalyzeText(inputText, lang);
      
      if (analysisResult) {
        trackEvent('Button', 'Click button analisi latino', 'Analyzed latin text');
        return analysisResult;
      }
    } catch (error) {
      console.error('Analysis error:', error);
      throw error;
    }
  }, [inputText, handleAnalyzeText, trackEvent]);


  const handleStartProcessVersion = useCallback(async (lang) => {
    setError(null);
    if (!user) {
      setOpenSignup(true);
      return;
    }

    if(user && !userData.termsAccepted) {
      setOpenPopupTerms(true);
      return;
    }

    if (inputText === "") {
      setError(
        <span>
          Cosa vuoi chiedere a Teseo?
        </span>
      )
      return;
    }
    
    if (inputText == previousTextRef.current) {
      setError(
        <span>
          Cambia il testo da chiedere a Teseo!
        </span>
      )
      return;
    }

    previousTextRef.current = inputText; // Update the ref to the current text

    if ((lang==="lat" && userData.latinoCount <= 0)||(lang==="grc" && userData.grecoCount <= 0)) {
      setError(
        <span>
          Hai raggiunto il limite per questo mese!
          {userData["type"] == "normal" && <Link className='socialTeseo mx-1' to={'/pay'}>Passa a Teseo+</Link>}
        </span>
      );
      console.log("limite raggiunto!");

      return;
    }

    resetState();

    setShowVersionResults(true);
    setDisabled(true);
    setLoading(true);

    try {
      const [translationResult, analysisResult] = await Promise.all([
        handleTranslate(lang),
        handleAnalysis(lang)
      ]);

      if (translationResult.status && analysisResult.status) {
        await updateLimitInContext(lang);
        await saveProcessedVersion(inputText, lang, translationResult.outputTranslation, analysisResult.outputAnalysis)
      }

    } catch (error) {
      console.error('Error in process:', error);
      // Handle error appropriately
    }
    finally {
      // Reset the loading and disabled states
      setDisabled(false);
      setLoading(false);
    }
  }, [
    user,
    inputText,
    userData.latinoCount,
    handleTranslate,
    handleAnalysis,
    updateLimitInContext
  ]);




  return {
    handleStartProcessVersion,
    showVersionResults,
    openSignup,
    setOpenSignup,
    openPopupTerms, 
    setOpenPopupTerms,
  };
};