import { useState, useCallback } from 'react';

export const useUIState = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [progress, setProgress] = useState(0);

  const resetState = useCallback(() => {
    setLoading(false);
    setError(null);
    setDisabled(false);
    setProgress(0);
  }, []);
  
  return {
    loading,
    setLoading,
    error,
    setError,
    disabled,
    setDisabled,
    progress,
    setProgress,
    resetState,
  };
};