import React, { useState, useEffect } from 'react';
import TranslationBox from './TranslationBox';
import Analysis from './analysis/Analysis';
import styles from '../styles/ResultsTabs.module.css';

const ResultsTabs = ({ translation, analysis, lemma, userData, loading, progress }) => {
  const [activeTab, setActiveTab] = useState('translation');

  return (
    <div className={styles.container}>
      <div className='d-flex mb-3'>
        <button
          onClick={() => setActiveTab('translation')}
          className={`${styles.tab} ${
            activeTab === 'translation' ? styles.activeTab : styles.inactiveTab
          }`}
        >
          <span className='inter-medium'>TRADUZIONE</span>
        </button>
        <button
          onClick={() => setActiveTab('analysis')}
          className={`${styles.tab} ${
            activeTab === 'analysis' ? styles.activeTab : styles.inactiveTab
          }`}
        >
          <span className='inter-medium'>ANALISI</span>
        </button>
      </div>

      <div className={styles.content}>
        {activeTab === 'translation' && (
          <TranslationBox object={translation} />
        )}
        
        {activeTab === 'analysis' && (
          <Analysis 
            analysis={analysis}
            lemma={lemma}
            userData={userData}
            loading={loading}
            progress={progress}
          />
        )}
      </div>
    </div>
  );
};

export default ResultsTabs;