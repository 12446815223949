import React, { useState, useEffect } from 'react';
import { useUserAuth } from "../components/UserAuthContext";
import { MdVerified } from 'react-icons/md';
import { IoIosLogOut } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styles from '../styles/Navbar.module.css';
import logo from '../images/logo/logo_white.svg';

const Navbar = () => {
  const location = useLocation();
  const { user, userData, logOut } = useUserAuth();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to handle menu toggle
  const userType = userData["type"];
  const userName = userData["nome"];
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      // Make navbar visible when scrolling up or at the top of the page
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 2);

      // Smooth scroll transition
      window.scrollTo({
        top: currentScrollPos,
        behavior: 'smooth'
      });

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    if (location.pathname === "/complete-signup") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`background-teseo-component white w-full z-50 transition-all duration-500 transform ${visible ? 'fixed top-0 left-0 opacity-100' : 'absolute top-0 left-0 opacity-0 -translate-y-full'}`}>
      <div className="max-w-8xl mx-3 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* Logo */}
          <Link to={disabled ? '#' : '/'}>
            <div className="flex-shrink-0 flex items-center">
              <img src={logo} alt="Logo" className={styles.nav_logo_image} />
              <span className='bold fontXL mx-2'>Teseo.app</span>
            </div>
          </Link>

          {/* Navigation Links - Always visible */}
          <div className="flex items-center hidden md:block">
            <div className="flex items-center space-x-4 sm:space-x-8">
              {user ? (
                <div className='d-flex align-items-center'>
                  {userType === 'normal' && (
                    <Link to={disabled ? '#' : '/pay'} className="text-white hover:font-bold px-2 sm:px-3 rounded-md text-sm font-medium inter-regular transition-colors duration-300">
                      <span className='border-solid border-slate-300 border-r-2 h-full px-3'>Piani</span>
                    </Link>
                  )}

                  {userType === 'lifetime' || userType === 'premium' ? (
                    <div className='d-flex align-items-center'>
                      <MdVerified size={15} className='white mx-1' />
                      <Link to={disabled ? '#' : '/profilo'} className="socialTeseo no-underline fontM inter-regular">Ave <span className='underline'>{userName}</span></Link>
                    </div>
                  ) : (
                    <Link to={disabled ? '#' : '/profilo'} className="px-2 sm:px-3 py-2 transition-colors fontM inter-regular"> Ave <span className='underline'>{userName}</span></Link>
                  )}
                </div>
              ) : (
                <div>
                  <Link to='/pay' className="text-white hover:font-bold px-2 sm:px-3 rounded-md text-sm font-medium inter-regular transition-colors duration-300">
                    Piani
                  </Link>
                  <Link to='/login'
                    className="bg-white text-[#1f1f1f] hover:bg-gray-400 hover:font-bold px-4 py-2 mx-2 rounded-md text-sm font-medium inter-regular transition-all duration-300">
                    Accedi
                  </Link>
                  <Link to='/signup'
                    className="background-teseo text-white hover:font-bold px-4 py-2 rounded-md text-sm font-medium inter-regular transition-all duration-300 ease-in-out shadow-md hover:shadow-lg">
                    Iscriviti gratis
                  </Link>
                </div>
              )
              }
            </div>
          </div>

          <div className="md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMenuOpen}
              onClick={toggleMenu}
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>

        </div>
      </div>

      <div
        className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'
          } bg-[#1f1f1f] px-2 pt-2 pb-3 space-y-1 sm:px-3`}
      >
        {
          user ? (
            <div className='d-flex flex-column px-2 gap-3'>
              {userType === 'lifetime' || userType === 'premium' ? (
                <div className='d-flex align-items-center'>
                  <MdVerified size={15} className='white' />
                  <Link to={disabled ? '#' : '/profilo'} className="socialTeseo no-underline fontM inter-regular">Ave <span className='underline'>{userName}</span></Link>
                </div>
              ) : (
                <Link to={disabled ? '#' : '/profilo'} className="transition-colors fontM inter-regular"> Ave <span className='underline'>{userName}</span></Link>
              )}
              {userType === 'normal' && (
                (disabled ?
                  <Link onClick={logOut} className="block text-white hover:font-bold rounded-md font-medium inter-regular transition-colors duration-300">
                    <IoIosLogOut fontSize={20}></IoIosLogOut> Logout
                  </Link>
                  :
                  <Link to={'/pay'} className="block text-white hover:font-bold rounded-md font-medium inter-regular transition-colors duration-300">
                    <span>Piani</span>
                  </Link>
                )

              )}
            </div>
          ) : (
            <div>
              <Link to='/pay'
                className="block px-3 py-2 rounded-md text-base font-medium text-white inter-regular hover:bg-gray-700 hover:text-white">
                Piani
              </Link>
              <Link to='/login'
                className="block px-3 py-2 rounded-md text-base font-medium text-white inter-regular hover:bg-gray-700 hover:text-white">
                Accedi
              </Link>
              <Link to='/signup'
                className="block px-3 py-2 rounded-md text-base font-medium text-white inter-regular hover:bg-gray-700 hover:text-white">
                Iscriviti gratis
              </Link>
            </div>
          )}
      </div>
    </nav>
  );
};

export default Navbar;