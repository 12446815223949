import styles from '../styles/TButton.module.css'

export default function TButton(props){
    const dis = props.disabled;
    const textButton = props.text; 

    return(
        <div className="d-flex justify-content-center align-items-center mx-3">
            <button disabled={dis} onClick={props.onClick} className={`${styles.tbutton} white regular px-4 py-2`}>
                {textButton}
            </button>
        </div>
    )
}