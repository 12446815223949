import styles from '../styles/GButton.module.css'

export default function GButton(props){
    const { text, icon, onClick } = props;  

    return(
        <div className="d-flex justify-content-center align-items-center">
            <button
                className={`${styles.gbutton} white bold px-4 py-2 d-flex justify-content-center align-items-center`}
                onClick={onClick}
            >
                {icon && <span className={`${styles.icon}`}>{icon}</span>}
                {text}
            </button>
        </div>
    )
}