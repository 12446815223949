function calculateNextUpdate(lastUpdateTimestamp) {
    if (!lastUpdateTimestamp || !lastUpdateTimestamp._seconds) {
      return 'N/A';
    }
  
    const lastUpdate = new Date(lastUpdateTimestamp._seconds * 1000);
    const nextUpdate = new Date(lastUpdate.getFullYear(), lastUpdate.getMonth() + 1, lastUpdate.getDate());
    
    return nextUpdate.toLocaleDateString('it-IT', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  }

  export default calculateNextUpdate;