import calculateNextUpdate from "../utils/calculateNextUpdate";
import { Link } from 'react-router-dom';
import React, {useMemo} from 'react';
import { Row, Col } from 'react-bootstrap';
import { IoAlertCircleOutline } from "react-icons/io5";
import styles from '../styles/TextareaUser.module.css';


export default function CounterBanner(props) {
    const userData = props.userData;
    const user = props.user;
    const nextUpdateDate = useMemo(() => calculateNextUpdate(userData.lastUpdate), [userData.lastUpdate]);
    const lang = props.lang;

    return (
        <div className='justify-content-end d-flex'>
            {
                user && (
                    lang === "lat" ? (
                        <Row className={styles.countBox}>
                            <Col className='justify-content-end d-flex align-items-center'><IoAlertCircleOutline size={17} className="mx-1"></IoAlertCircleOutline>Hai ancora {userData["latinoCount"] === 0 ? '0' : userData["latinoCount"]} versioni di latino fino al {nextUpdateDate}</Col>
                        </Row>
                    ) : (
                        <Row className={styles.countBox}>
                            <Col className='justify-content-start d-flex align-items-center'><IoAlertCircleOutline size={17} className="mx-1"></IoAlertCircleOutline>Hai ancora {userData["grecoCount"]} versioni di greco fino al {nextUpdateDate}</Col>
                        </Row>
                    ))
            }
        </div>
    )
}