import React from "react";
import {Helmet} from "react-helmet";

export default function CustomHead({ title, description, keywords, canonicalUrl }) {
    return (
        <Helmet>
            {/* Imposta il titolo della pagina */}
            <title>{title}</title>

            {/* Descrizione per i motori di ricerca */}
            <meta name="description" content={description} />

            {/* Parole chiave per i motori di ricerca */}
            <meta name="keywords" content={keywords} />

            {/* Tag autore */}
            <meta name="author" content="Esperti in Traduzione Greco e Latino" />

            {/* Meta tag per la viewport */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            {/* Meta tag OpenGraph per la condivisione sui social */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:locale" content="it_IT" />

            {/* Link canonico per evitare contenuti duplicati */}
            <link rel="canonical" href={canonicalUrl} />

            {/* Charset */}
            <meta charSet="UTF-8" />

            {/* Favicon */}
            <link rel="icon" href="/favicon.ico" />
        </Helmet>
    );
}