import React from 'react';
import {motion} from 'framer-motion';
import { FaQuoteLeft } from "react-icons/fa";

import styles from "../styles/SliderCard.module.css";

const SliderCard = ({ review }) => {
    return (
        <motion.div className={`${styles.sliderCard}`}>
            <div className={`${styles.sliderCard_box} background-teseo-component`}>
                <motion.div className={styles.sliderCard_box_text}>
                    <div className='red p-2'><FaQuoteLeft></FaQuoteLeft></div>
                    <div className='p-3 fontM white regular'>{review.text}</div>
                    <div className={styles.sliderCard_author}>
                        <div className='d-flex justify-content-end fontM'>{review.author}</div>
                        <div className='fontM'>{review.place}</div>
                    </div>
                </motion.div>
            </div>
            
        </motion.div>
    );
};

export default SliderCard;