import { MdMenuBook } from "react-icons/md";
import { Link } from "react-router-dom";

export default function WrapperLemma(props) {

    const {lemma, type} = props;

    return (
        <div className="d-flex align-items-center">
            <MdMenuBook></MdMenuBook>
            {(type === "premium" || type === "lifetime") ? (
                <span className="premium-tag mx-1"> Cerca sul dizionario come: {lemma}</span>
            ) : 
                <span className="fontS mx-1 underline"> <Link to='/pay'>Come la cerco sul dizionario?</Link></span>
            }
        </div>
    );
}