import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useUserAuth } from "./UserAuthContext";
import { MdVerified } from "react-icons/md";
import ShareLink from './ShareLink';


const Teseoplus = ({openPopup}) => {

    const { user } = useUserAuth(); 
    const [userType, setUserType] = useState('');

    /* useEffect(() => {
        const fetchUserType = async () => {
            try {
                if (user != '' && user != null) {
                    const type = await getType(user);
                    setUserType(type);
                } else {
                    setUserType('normal');
                }
            } catch (error) {
                console.error("Error fetching user type:", error);
            }
        };

        fetchUserType();
    }, [user]);

    if (userType === null) {
        return <p>Loading...</p>; 
    } */

    const handleClickPayNotAuth = () => {
        openPopup(true);
    };

    return(
        <>
            <Row className='align-items-center justify-content-center text-center'>
                
                <Col lg={6} xs={10} className='d-flex align-items-center justify-content-center flex-column background-teseo-gradient radius-teseo py-3'>
                    <p className="fontXXL bold pt-3 regular">Teseo ti adora</p>
                    <div className="fontL regular d-flex">
                        <MdVerified size={27} className='white mx-lg-1'/> 
                        <span className=''> Fai parte della community!</span>
                    </div>
                    <ShareLink></ShareLink>
                </Col>    
                            
            </Row>
        </>
    );
}

export default Teseoplus;