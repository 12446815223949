import React, { useState } from 'react';
import { Col } from "react-bootstrap";
import styles from "../styles/Resultbox.module.css"
import { TiPlus, TiMinus } from "react-icons/ti";
import CopyButton from "./CopyButton";

export default function TranslationBox(props) {

    const object = props.object;

    const [fontSize, setFontSize] = useState(16); // default font size in pixels
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Handler to increase font size
    const increaseFontSize = () => {
        if (fontSize > 52) {return;}
        setFontSize(prevSize => prevSize + 2); // increase by 2 pixels
    };
    const decreaseFontSize = () => {
        if (fontSize < 6) {return;}
        setFontSize(prevSize => prevSize - 2); // increase by 2 pixels
    };

    const toggleModal = () => {
        setIsModalOpen(prev => !prev);
    };

    // Styles for the result tab
    const tabStyles = {
        fontSize: `${fontSize}px`,
        transition: 'font-size 0.3s ease', // smooth transition for font size change
    };

    return(
        <Col lg={12} md={12} sm={12} xs={12} className={`${styles.resultBox} bg-black radius-teseo justify-content-center mx-3 my-3 fontM p-4 position-relative max-h-96`}> 
            
            <div className='relative'>
                <p className={styles.result_box_placeholder}>
                    Traduzione del testo
                </p>
                <p style={tabStyles}>   
                    {object}
                </p>
                <div className="sticky d-flex justify-content-end bottom-0">
                    <div className={`${styles.fontButton} d-flex flex-column`}>
                        <button onClick={increaseFontSize} className={`${styles.fontButtonIcon}`}><TiPlus></TiPlus></button>
                        <button onClick={decreaseFontSize} className={`${styles.fontButtonIcon} d-flex align-items-center`}><TiMinus></TiMinus></button>
                    </div>    
                </div>  
            </div>

        </Col>
    );
}