import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styles from '../styles/UploadModal.module.css';
import TButton from '../components/TButton';
import { FaFileImage, FaUndoAlt, FaCrop } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";


export default function UploadModal({ isOpen, onRequestClose, onPhotoTaken, setPhoto, photo }) {
  const [preview, setPreview] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [imageRef, setImageRef] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [step, setStep] = useState(null);

  useEffect(() => {
    // Reset state
    setStep(null);
    setPreview(null);
    setCrop(null);
    setCompletedCrop(null);
    setRotation(0);
    setImageRef(null);
  }, [isOpen]);

  const onImageLoad = useCallback((img) => {
    setImageRef(img);
  }, []);

  const startCrop = () => {
    setStep('crop');
    setCrop({ aspect: 1, width: 100, x: 0, y: 0 });
  };

  const confirmCrop = async () => {
    if (!imageRef || !completedCrop?.width || !completedCrop?.height) {
      alert("Completa il ritaglio prima di confermare.");
      return;
    }

    try {
      const croppedBlob = await getCroppedImage(imageRef, completedCrop);
      const croppedImageURL = URL.createObjectURL(croppedBlob);

      // Update the preview with the cropped image
      if (preview) {
        URL.revokeObjectURL(preview);
      }
      setPreview(croppedImageURL);

      // Update the photo file with the cropped image
      const croppedFile = new File([croppedBlob], 'cropped-image.jpg', {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });
      setPhoto(croppedFile);

      setStep('imgup');
    } catch (error) {
      console.error("Error while confirming crop:", error);
      alert("Si è verificato un errore durante il ritaglio dell'immagine.");
    }
  };

  const startRotate = () => {
    setStep('rotate');
  };

  const confirmRotate = async () => {
    if (!imageRef) return;

    try {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const radians = (rotation * Math.PI) / 180;

      // Calculate new canvas dimensions for the rotated image
      const sin = Math.abs(Math.sin(radians));
      const cos = Math.abs(Math.cos(radians));
      canvas.width = imageRef.naturalWidth * cos + imageRef.naturalHeight * sin;
      canvas.height = imageRef.naturalWidth * sin + imageRef.naturalHeight * cos;

      // Center the image and apply rotation
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(radians);

      // Draw the rotated image
      ctx.drawImage(
        imageRef,
        -imageRef.naturalWidth / 2,
        -imageRef.naturalHeight / 2,
        imageRef.naturalWidth,
        imageRef.naturalHeight
      );

      const rotatedBlob = await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          resolve(blob);
        }, 'image/jpeg');
      });

      // Update preview and photo
      const rotatedImageURL = URL.createObjectURL(rotatedBlob);
      setPreview(rotatedImageURL);

      const rotatedFile = new File([rotatedBlob], 'rotated-image.jpg', {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });
      setPhoto(rotatedFile);
      setRotation(0);
      setStep('imgup');
    } catch (error) {
      console.error('Error while confirming rotation:', error);
      alert("Si è verificato un errore durante la rotazione dell'immagine.");
    }
  };

  const rotateImage = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    processFile(selectedFile);
  };

  const processFile = (file) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    setPhoto(file);
    if (file && allowedTypes.includes(file.type)) {
      setPhoto(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setStep('imgup');
        setCrop(null);
        setCompletedCrop(null);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Solo immagini in formato JPEG, JPG o PNG sono consentite.");
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      processFile(files[0]);
    }
  };

  const handleCropComplete = useCallback((crop) => {
    setCompletedCrop(crop);
  }, []);

  const getCroppedImage = async (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const handleSubmit = async () => {
    if (!photo) {
      alert("Seleziona una foto.");
      return;
    }

    try {
      onPhotoTaken(photo);
    } catch (e) {
      console.error('Error during image processing:', e);
      alert('Si è verificato un errore durante la modifica dell\'immagine.');
    }
  };

  const handleClose = () => {
    onRequestClose();
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modalContent} overlayClassName={styles.modalOverlay}>
      <div className={styles.uploadContainer}>
        <div>
          <button onClick={handleClose} className={styles.button}><IoMdClose size={25}></IoMdClose></button>
        </div>

        <div
          className={`${styles.container} ${isDragging ? styles.dragging : ''} radius-teseo p-3`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {/* IMAGE PREVIEW */}
          {step === null && (
            <div className={styles.header}>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                <path d="M7 10V9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9V10C19.2091 10 21 11.7909 21 14C21 15.4806 20.1956 16.8084 19 17.5M7 10C4.79086 10 3 11.7909 3 14C3 15.4806 3.8044 16.8084 5 17.5M7 10C7.43285 10 7.84965 10.0688 8.24006 10.1959M12 12V21M12 12L15 15M12 12L9 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> <p>Nessuna foto caricata!</p>
              <p>Trascina la tua foto qui o clicca per selezionare un file</p>
            </div>
          )}
          {preview && step === 'imgup' && (
            <div>
              <div>
                <h5 className="fontS my-3 text-center opacity-80">Seleziona l'area in cui c'è il testo della versione</h5>
              </div>
              <div className={styles.cropContainer}>
                <img
                  ref={setImageRef}
                  src={preview}
                  className={styles.img}
                  onLoad={(e) => onImageLoad(e.currentTarget)}
                  alt="Preview"
                />
              </div>
            </div>
          )}
          {preview && step === 'rotate' && (
            <div>
              <div>
                <h5 className="fontS my-3 text-center opacity-80">Seleziona l'area in cui c'è il testo della versione</h5>
              </div>
              <div className={styles.rotateContainer}>
                <img
                  ref={setImageRef}
                  src={preview}
                  className={styles.img}
                  onLoad={(e) => onImageLoad(e.currentTarget)}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  alt="Preview"
                />
              </div>
            </div>
          )}
          {preview && step === 'crop' && (
            <div>
              <div>
                <h5 className="fontS my-3 text-center opacity-80">Seleziona l'area in cui c'è il testo della versione</h5>
              </div>
              <div className={styles.cropContainer}>
                <ReactCrop
                  src={preview}
                  crop={crop}
                  onChange={(_, newCrop) => setCrop(newCrop)}
                  onComplete={handleCropComplete}
                  className={styles.reactCrop}
                >
                  <img
                    ref={setImageRef}
                    src={preview}
                    className={styles.img}
                    onLoad={(e) => onImageLoad(e.currentTarget)}
                    alt="Preview"
                  />
                </ReactCrop>
              </div>
            </div>
          )}

          {/* BUTTONS */}
          <div className={`${styles.buttonContainer} my-2`}>
            {step === null && (
              <>
                <label for="file" className={`${styles.footer} radius-teseo my-2 p-1`}>
                  <div className='d-flex justify-content-center p-2 align-items-center'><FaFileImage className='white mx-1' fontSize={20}></FaFileImage><p>Seleziona o scatta foto</p></div>
                </label>
                <input
                  id="file"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className={styles.fileInput} />
              </>
            )}

            {step === 'imgup' && (
              <>
                <button onClick={startCrop} className={styles.rotateButton} disabled={!photo}>
                  <FaCrop className={styles.rotateIcon} /> Ritaglia
                </button>
                <button onClick={startRotate} className={styles.rotateButton} disabled={!photo}>
                  <FaUndoAlt className={styles.rotateIcon} /> Ruota
                </button>
              </>
            )}

            {step === 'crop' && (
              <button onClick={confirmCrop} className={styles.rotateButton}>
                Conferma Ritaglio
              </button>
            )}

            {step === 'rotate' && (
              <>
                <button onClick={rotateImage} className={styles.rotateButton}>
                  <FaUndoAlt className={styles.rotateIcon} /> Ruota
                </button>
                <button onClick={confirmRotate} className={styles.rotateButton}>
                  Conferma Rotazione
                </button>
              </>
            )}
          </div>
        </div>

        <div className="mt-3">
          {step === 'imgup' && (
            <TButton text="Carica!" onClick={handleSubmit} disabled={!photo} />
          )}
        </div>
      </div>
    </Modal>
  );
}