import React from 'react';
import { Row } from 'react-bootstrap';
import styles from '../styles/OcrWidget.module.css';
import { MdOutlineFileUpload } from "react-icons/md";
import { useNavigate } from 'react-router';


export default function OcrWidgetNotSubscribed() {
    const navigate = useNavigate();

    return (
        <div className={styles.ocrWrapper}>
            <Row className="h-full p-4 radius-teseo d-flex justify-content-center">
                <div className='d-flex justify-content-center align-items-center flex-column'>
                    <span className='regular fontL white text-center my-2'>Scatta una foto e traduci</span>

                    <button onClick={() => navigate("/pay")} className={`${styles.caricaButton} radius-teseo w-full h-full`}>< MdOutlineFileUpload fontSize={30}></MdOutlineFileUpload></button>
                </div>
            </Row>
        </div>
    );
}
