
const divideText = (text, maxWords = 13) => {
    
    const tokens = text.split(/\s+/);

    const subtexts = [];
    let currentSubtext = '';
    let wordCount = 0;

    for (const token of tokens) {
      currentSubtext += token + ' ';
      wordCount++;

      if (wordCount >= maxWords) {
        subtexts.push(currentSubtext.trim());
        currentSubtext = '';
        wordCount = 0;
      }
    }

    if (currentSubtext.trim() !== '') {
      subtexts.push(currentSubtext.trim());
    }

    return subtexts;
  };

export default divideText;