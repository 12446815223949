import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../styles/TeseoPlusBanner.module.css';

const TeseoPlusBannerSticky = () => {
    return(
        <div className={`${styles.stickyBanner} radius-teseo`}>
            <Row className='justify-content-evenly align-items-center'>
                <Col lg={5} md={5} sm={6} xs={4} className='d-flex flex-column'>
                    <span className="bold fontXXL lh-1">Teseo+</span>
                    <span className="regular fontM desktop-only">Sblocca i vantaggi della versione plus</span>
                </Col>
                <Col sm={6} xs={8} className='d-flex justify-content-end align-items-center'>
                    <Link to='/pay' className={`${styles.buttonStickyBanner} px-2 py-2 regular text-center fontM lh-1`}><span>SBLOCCA ORA</span></Link>
                </Col>
            </Row>
        </div>
    );
}

export default TeseoPlusBannerSticky;
