import { Row, Col, Button} from 'react-bootstrap';
import { useNavigate } from "react-router";
import styles from '../styles/NotFound.module.css'

const NotFound = () => {

    const navigate = useNavigate();

    return (
        <>
           <Row className={`${styles.box} px-lg-4 mx-0 px-2 d-flex flex-col justify-content-center align-items-center`}>
                <Col className={`${styles.top} col-lg-8 col-11 d-flex justify-content-start`}>
                    <p className="bold fontXL">Errore 404: pagina non trovata</p>
                </Col>
                <Col className={`${styles.message_box} col-lg-8 col-11 d-flex flex-col justify-content-start align-items-start radius-teseo`}>
                {/*<img src={logo} alt="Logo" className={styles.logo_image} />*/}
                    <div className={`${styles.ypadding}`}>
                        <p className="bold fontL pb-1">Ti sei perso!</p>
                        <p classname="inter-regular fontM">Sembra che tu abbia perso il filo di Arianna.<br></br>
                        Ecco un modo per tornare alla pagina iniziale</p>
                    </div>
                    
                    <Button onClick={() => navigate("/")} className={`${styles.goback_button} fontM`}>
                        PAGINA INIZIALE
                    </Button> 
                </Col>
            </Row>    
        </>
    );
};

export default NotFound;