import { useState, useCallback, useEffect } from 'react';
import divideText from '../utils/divideText';
import api from '../API/backend';
import { useUserAuth } from "../components/UserAuthContext";

const API_URL = process.env.REACT_APP_BACKEND_PATH;

// TODO : BETTER HANDLING ERRORI 

export const useAnalysis = (uiState) => {
  const [analysis, setAnalysis] = useState(() => {
    const savedAnalysis = localStorage.getItem('displayedAnalysis');
    return savedAnalysis ? JSON.parse(savedAnalysis) : {};
  });

  const [greekAnalysis, setGreekAnalysis] = useState(() => {
    const savedAnalysisGreek = localStorage.getItem('displayedAnalysisGreek');
    return savedAnalysisGreek ? JSON.parse(savedAnalysisGreek) : {};
  });

  const [lemma, setLemmas] = useState(() => {
    const savedAnalysisLemma = localStorage.getItem('displayedAnalysisLemma');
    return savedAnalysisLemma ? JSON.parse(savedAnalysisLemma) : {};
  });

  const { userData } = useUserAuth();
  const { setError, setProgress } = uiState;


  const handleUpdateAnalysis = useCallback((data, currentIndex, totalParts) => {
    setAnalysis(prev => ({ ...prev, ...data }));
    setProgress(((currentIndex + 1) / totalParts) * 100); 
  }, []);

  const handleUpdateGreekAnalysis = useCallback((data, currentIndex, totalParts) => {
    setGreekAnalysis(prev => ({ ...prev, ...data }));
    setProgress(((currentIndex + 1) / totalParts) * 100);
  }, []);


  const handleUpdateError = useCallback((errorMessage) => {
    setError(errorMessage);
  }, []);


  useEffect(() => {
    localStorage.setItem('displayedAnalysisGreek', JSON.stringify(greekAnalysis));
    //console.log("analisi greco cambiata", greekAnalysis);
  }, [greekAnalysis]);
  useEffect(() => {
    localStorage.setItem('displayedAnalysis', JSON.stringify(analysis));
    //console.log("analisi cambiata", analysis); 
  }, [analysis]);
  useEffect(() => {
    localStorage.setItem('displayedAnalysisLemma', JSON.stringify(lemma));
    //console.log("lemma cambiata", lemma);
  }, [lemma]);


  const analyzeText = useCallback(async (inputText, lang) => {
    const subtexts = divideText(inputText);
    let allSuccessful = true;

    for (let i = 0; i < subtexts.length; i++) {
      try {
        const response = await api.post(`${API_URL}/analyze/dynamic`, { text: subtexts[i], lang: lang });
        handleUpdateAnalysis(response.data, i, subtexts.length);
      } catch (error) {
        handleAnalysisError(error, handleUpdateError);
        allSuccessful = false;
      }
    }

    return allSuccessful;
  }, [handleUpdateAnalysis, handleUpdateError]);




  const analyzeTextGreek = useCallback(async (inputText, lang) => {
    
    const cleanedText = inputText.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
    const subtexts = divideText(cleanedText);
    let allSuccessful = true;

    for (let i = 0; i < subtexts.length; i++) {
      try {
        const response = await api.post(`${API_URL}/analyze/greek`, { text: subtexts[i], lang: lang });
        handleUpdateGreekAnalysis(response.data, i, subtexts.length);
      } catch (error) {
        handleAnalysisError(error, handleUpdateError);
        allSuccessful = false;
      }
    }

    return allSuccessful;
  }, [handleUpdateGreekAnalysis, handleUpdateError]);




  const fetchLemmas = useCallback(async (inputText, lang) => {
    
    try {
      const response = await api.post(`${API_URL}/analyze/lemmas`, { text: inputText, lang: lang });
      setLemmas(prev => ({ ...prev, ...response.data }));
    } catch (error) {
      handleAnalysisError(error, handleUpdateError);
    }
  }, []);






  const handleAnalyzeText = useCallback(async (inputText, lang) => {
    try {
      setError('');
      setProgress(0);

      let analysisResult;

      if(lang === "lat"){
        setAnalysis({});
        analysisResult = await analyzeText(inputText.toLowerCase(), lang);
        
        if(userData["type"] === "lifetime" || userData["type"] === 'premium'){
          setLemmas({});
          const lemmas = await fetchLemmas(inputText, lang);
        }
      }

      else if(lang === "grc") {
        setGreekAnalysis({});
        analysisResult = await analyzeTextGreek(inputText.toLowerCase(), lang);
      }

      return ({
        status: analysisResult,
        outputAnalysis: lang==="lat" ? analysis : greekAnalysis
      })
    } catch (error) {
      setError(error.message);
      return false;
    } finally {
      
    }
  }, [uiState]);

  return {
    analysis,
    greekAnalysis,
    lemma,
    handleAnalyzeText
  };
};

const handleAnalysisError = (error, updateError) => {
  const TEXT_ERROR_MESSAGES = {
    400: 'Teseo traduce solo testi latini!',
    429: 'Teseo ha lavorato troppo per te oggi, torna domani.',
    default: 'Teseo si è perso nel labirinto e non può aiutarti ora.'
  };
  
  let errorMessage = 'Teseo non risponde';

  if (error.response) {
    const statusCode = error.response.status;
    errorMessage = TEXT_ERROR_MESSAGES[statusCode] || TEXT_ERROR_MESSAGES.default;
  } else if (error.request) {
    console.error('Request Error:', error.request);
  } else {
    console.error('Request Setup Error:', error.message);
  }

  updateError(errorMessage);
  throw new Error(errorMessage);
};