import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion"; // Or react-transition-group if preferred
import { useEffect } from 'react';
import Home from './pages/Home';
import Greco from './pages/Greco';
import Login from './pages/login';
import SignupWithGoogle from './pages/signupWithGoogle';
import Signup from './pages/signup';
import Navbar from './components/Navbar';
import CheckoutYes from './stripe/CheckoutYes';
import CheckoutNo from './stripe/CheckoutNo';
import Profilo from './pages/profilo';
import Footer from './components/Footer';
import { UserAuthContextProvider } from './components/UserAuthContext';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {NeedRegistrationRoute, AuthenticateRoute, NonAuthenticateRoute, HomeProtectedRoute, AuthenticatedAndRegisteredRoute, OnlyNormalUserRoute } from './components/ProtectedRoutes';
import PaymentPage from './stripe/PaymentPage';
import NotFound from './pages/notfound';

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes>
          <Route path="/" element={<HomeProtectedRoute><Home /></HomeProtectedRoute>} />
          <Route path="/greco" element={<HomeProtectedRoute><Greco /></HomeProtectedRoute>} />
          {/* Add more routes here if needed */}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}


function App() {

  return (
    <UserAuthContextProvider>
    
      <Router>
      
        <Navbar></Navbar>
        <AnimatePresence> {/* Wrap with AnimatePresence for exit animations */}
        <Routes>
          
          <Route path="/" element={<HomeProtectedRoute><Home /></HomeProtectedRoute>} />
          <Route path="/greco" element={<HomeProtectedRoute><Greco /></HomeProtectedRoute>} />
          <Route path="/login" element={<NonAuthenticateRoute><Login /></NonAuthenticateRoute>} />
          <Route path="/complete-signup" element={<NeedRegistrationRoute><SignupWithGoogle /></NeedRegistrationRoute>} />
          <Route path="/signup" element={<NonAuthenticateRoute><Signup /></NonAuthenticateRoute>} />
          <Route path="/profilo" element={<AuthenticateRoute><Profilo /></AuthenticateRoute>} />
          <Route path="/checkoutyes" element={<AuthenticateRoute><CheckoutYes /></AuthenticateRoute>} />
          <Route path="/checkoutno" element={<AuthenticateRoute><CheckoutNo /></AuthenticateRoute>} />
          <Route path="/pay" element={<HomeProtectedRoute><PaymentPage /></HomeProtectedRoute>} />
          <Route path="*"element={<NotFound />}/>
        </Routes>
      </AnimatePresence>

        <Footer></Footer>
        
      </Router>
    </UserAuthContextProvider>
    );
};

export default App;