import React from 'react';

const ShareLink = ({ message }) => {
  // Handler function for sharing
  const handleShare = async () => {
    // Check if the Web Share API is available
    if (navigator.share) {
      try {
        await navigator.share({
          text: message,
          url: window.location.href, // optional: shares the current URL
        });
        alert("Message shared successfully!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // If Web Share API is not available, copy the message to clipboard
      try {
        await navigator.clipboard.writeText(`${message} ${window.location.href}`);
        alert("Message copied to clipboard! Now you can paste it on social media.");
      } catch (error) {
        console.error("Failed to copy:", error);
        alert("Copying failed. Please try again.");
      }
    }
  };

  return (
    <a 
      onClick={handleShare}
      className="py-3 text-white rounded-md underline regular fontL cursor-pointer"
    >
      Dillo a tutti.
    </a>
  );
};

export default ShareLink;
