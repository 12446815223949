// General imports
import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import { motion, useInView } from 'framer-motion';
// Component imports
import Signup from './signup';
import TextAreaUser from '../components/TextareaUser';
import TButton from '../components/TButton';
import CommentSlider from '../components/CommentSlider';
import { useUserAuth } from "../components/UserAuthContext";
import NavigationSwitch from '../components/NavigationSwitch';
import CustomHead from '../components/CustomHead';
import Teseoplus from '../components/Teseoplus';
import ResultsTabs from '../components/ResultsTabs';
import { useTextOperations } from '../hooks/useTextOperations';
import OcrWidget from '../components/OcrWidget';
import { Circles } from 'react-loader-spinner'
import TeseoPlusBannerSticky from '../components/TeseoPlusBannerSticky';
import OcrWidgetNotSubscribed from '../components/OcrWidgetNotSubscribed';
// Utils imports
import { trackEvent } from '../utils/generalUtils';
import { useProcessVersion } from '../hooks/useProcessVersion';
import TeseoPlusBanner from '../components/TeseoPlusBanner';
import PopupTerms from '../components/PopupTerms';


function Home() {
  const { user,
    userData,
    updateLimitInContext,
    dataReady
  } = useUserAuth();

  const [inputText, setInputText] = useState(() => {
    const savedInputText = localStorage.getItem('inputText');
    return savedInputText ? savedInputText : '';
  });
  const [showOcrModal, setShowOcrModal] = useState(false);
  const previousTextRef = useRef('');
  const [loadingOCR, setLoadingOCR] = useState(false);

  const {
    loading,
    error,
    disabled,
    progress,
    analysis,
    lemma,
    translation,
    handleAnalyzeText,
    handleTranslateText,
    setDisabled,
    setLoading,
    resetState,
    setError,
  } = useTextOperations();

  const {
    handleStartProcessVersion,
    openSignup,
    setOpenSignup,
    openPopupTerms,
    setOpenPopupTerms,

  } = useProcessVersion({
    user,
    inputText,
    userData,
    handleTranslateText,
    handleAnalyzeText,
    updateLimitInContext,
    trackEvent,
    setDisabled,
    setLoading,
    resetState,
    setError,
    previousTextRef
  });

  useEffect(() => {
    document.body.classList.add('home-page');
    return () => {
      document.body.classList.remove('home-page');
    };
  }, []);

  /* useEffect(() => {
    if(analysis && Object.keys(analysis).length > 0) {
      setDisplayedAnalysis(analysis);
      localStorage.setItem('displayedAnalysis', JSON.stringify(analysis));
      console.log("analisi cambiata", analysis);
    }
  }, [analysis]); */

  const handleInputChange = (event) => {
    const newText = event.target.value;
    setInputText(newText);
    localStorage.setItem('inputText', newText);
  };

  const handleProcessVersionClick = (event) => {
    if (!dataReady) { return }
    handleStartProcessVersion("lat");
  }

  const handleCameraClick = () => {
    if (!user) {
      setOpenSignup(true);
    }
    else {
      setShowOcrModal(true);
    }
  }

  const refSticky = useRef(null);
  const refFixed = useRef(null);
  const isInView = useInView(refFixed, { once: false });

  return (
    <main className="App">
      <CustomHead
        title="Teseo.app | Traduci e analizza"
        description="Traduci e analizza la tua versione con l'intelligenza artificiale."
        keywords="teseo.app, teseo, latino, greco, traduzione, analisi, versione"
        author="Teseo"
        canonicalUrl="https://teseo.app/"
      />

      <Container className='pt-20'>
        {(openSignup || openPopupTerms) && <div className='blurred-background'></div>}
        {openSignup && <Signup closePopup={() => setOpenSignup(false)} popup={true} />}
        {openPopupTerms && <PopupTerms closePopup={() => setOpenPopupTerms(false)} />}

        <Row className='my-5 justify-content-center d-flex'>
          <span className='regular fontXL white text-center'>Traduci e analizza le tue versioni con l'intelligenza artificiale</span>
          <Col sm={3} xs={10} lg={3} className='text-center justify-content-center d-flex'>
            <NavigationSwitch disabled={disabled} />
          </Col>
        </Row>

        <Row className='gap-4 justify-content-center'>
          <Col lg={7} xs={12} className='background-teseo-component p-lg-5 p-3 radius-teseo h-fit'>
            <TextAreaUser
              initialize="Scrivi qui la tua versione di latino"
              lang="lat"
              defaultValue={inputText}
              setTextInput={handleInputChange}
              readOnly={false}
              startProcessVersion={handleStartProcessVersion}
              user={user}
              userData={userData}
              handleCameraClick={handleCameraClick}
              loading={loadingOCR}
            />

            <Row className='justify-content-center d-flex'>
              <Col sm={11} xs={11} className="d-flex justify-content-center mt-4">
                {loading &&
                  <Circles
                    height="40"
                    width="40"
                    color="#BB0A0B"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                }
                <div className='desktop-only'>
                  <TButton onClick={handleProcessVersionClick} text='Chiedi a Teseo' disabled={disabled}></TButton>
                </div>
              </Col>
              <span className='text-center regular fontS white mt-1 mx-1 red'>{error}</span>
            </Row>

            <Row className='justify-content-center'>
              <ResultsTabs
                translation={translation}
                analysis={analysis}
                lemma={lemma}
                userData={userData}
                loading={loading}
                progress={progress}
              />
            </Row>
          </Col>
          <Col lg={4}>
            {user ? (
              <div className='desktop-only h-50 max-h-72 background-teseo-component radius-teseo'>
                <OcrWidget setInputText={setInputText} showOcrModal={showOcrModal} setShowOcrModal={setShowOcrModal} language="lat" loading={loadingOCR} setLoading={setLoadingOCR} />
              </div>
            ) : (
              <div className='desktop-only h-50 max-h-72 background-teseo-component radius-teseo'>
                <OcrWidgetNotSubscribed />
              </div>
            )
            }
          </Col>
        </Row>

        {(!user || userData["type"] === 'normal') && (
          <motion.div
            ref={refSticky}
            initial={{ opacity: 1 }}
            animate={{ opacity: isInView ? 0 : 1 }}
            transition={{ duration: 0 }}
          >
            <TeseoPlusBannerSticky />
          </motion.div>
        )}
      </Container>

      <Row className="my-5">
        <CommentSlider lang={"lat"} />
      </Row>

      <Container>
        {user ?
          userData.type === 'normal' ?
            <Row className="mt-5 mb-5 mx-2">
              <motion.div
                ref={refFixed}
                initial={{
                  opacity: 0
                }}
                whileInView={{
                  opacity: 1,
                  transition: {
                    duration: 1
                  }
                }}>
                <TeseoPlusBanner />
              </motion.div>
            </Row>
            :
            <Teseoplus openPopup={setOpenSignup} />
          :
          <Row className="mt-5 mb-5 mx-2">
            <motion.div
              ref={refFixed}
              initial={{
                opacity: 0
              }}
              whileInView={{
                opacity: 1,
                transition: {
                  duration: 1
                }
              }}>
              <TeseoPlusBanner />
            </motion.div>
          </Row>
        }
      </Container>
    </main>
  );
}

export default Home;