import DOMPurify from 'dompurify';
import ReactGA from 'react-ga4';


export const sanitizeInput = (event) => {
    const sanitizedValue = event.target.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s,\'!?:;.\-_]/g, '');
    const finalSanitizedValue  = DOMPurify.sanitize(sanitizedValue);
    return finalSanitizedValue;
};

export const getButtonInfo = (location, user) => {

    // Check the current pathname and return the corresponding text and URL
    if (location.pathname === '/greco') {
        return { text: 'Traduci Latino', to: '/' };
    } else if (location.pathname === '/login') {
        return { text: 'Home', to: '/' };
    } else if (location.pathname === '/' && user) {
        return { text: 'Traduci Greco', to: '/greco' };
    } else if (location.pathname === '/') {
        return { text: 'Traduci Greco', to: '/signup' };
    }  
    else {
        return { text: 'Home', to: '/' };
    }
  };

export const trackEvent = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
      });
}