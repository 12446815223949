import api from '../API/backend';
const API_URL = process.env.REACT_APP_BACKEND_PATH;

const updateLimit = async (lang) => {
    try {
      const response = await api.post(`${API_URL}/limit/`, { lang: lang });

      // Return the updatedCounts to be used outside the function
      const updatedCounts = response.data;
      return updatedCounts;
    } catch (error) {
      console.error('Error updating counts:', error.response ? error.response.data.error : error.message);
      return null; // Return null or handle the error case if something goes wrong
    }
  };
  

export default updateLimit;