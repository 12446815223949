import React from "react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "./UserAuthContext";

export const ProtectedRoute = ({ children }) => {
  const { user, userData } = useUserAuth();

  if (!user || userData["type"] === "lifetime") {
    return <Navigate to="/" />;
  }

  return children;
};

// AuthenticateRoute
// For pages that could be access only if the user is auth
// if user is not authenticated returns to the homepage
export const AuthenticateRoute = ({ children }) => {
  const { user, userData} = useUserAuth();

  if (!user) { 
    return <Navigate to="/" />;
  }
  return children;
};

// NonAuthenticateRoute
// For pages that could be access only if the user is not auth
// if user is authenticated goes to profilo page
export const NonAuthenticateRoute = ({ children }) => {
  const { user } = useUserAuth();

  if (user) { 
    return <Navigate to="/profilo" />;
  }
  return children;
};

// NeedRegistrationRoute

// redirect to homepage if a registration is needed
export const NeedRegistrationRoute = ({ children }) => {
  const { isRegistered } = useUserAuth();

  if (isRegistered) { 
    return <Navigate to="/" />;
  }
  return children;
};

// Home Route 
// The user has to be registered
export const HomeProtectedRoute = ({ children }) => {
  const { user, isRegistered } = useUserAuth();

  if (user && !isRegistered) {
    return <Navigate to="/complete-signup"></Navigate>
  }
  
  return children;
};

// Auth and Registered Route
// If the user is not authenticated or is not registered could not access
// Redirect to the complete signup or logout 
export const AuthenticatedAndRegisteredRoute = ({ children }) => {
  const { user, userData, isRegistered, logOut } = useUserAuth();

  if (!user) { 
    return <Navigate to="/login" />;
  }

  if (!isRegistered) {
    return <Navigate to="/complete-signup"></Navigate>
  }
  
  return children;
};

export const OnlyNormalUserRoute = ({ children }) => {
  const { user, userData } = useUserAuth();

  if (userData["type"] == "lifetime" || userData["type"] == "premium") {
    return <Navigate to="/profilo"></Navigate>
  }
  
  return children;
};
