import { useUIState } from "./useUIState";
import { useAnalysis } from "./useAnalysis";
import { useTranslation } from "./useTranslation";

export const useTextOperations = () => {
    const uiState = useUIState();
    const analysisHook = useAnalysis(uiState);
    const translationHook = useTranslation(uiState);
  
    return {
      ...uiState,
      ...analysisHook,
      ...translationHook,
    };
  };