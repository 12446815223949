import axios from 'axios';
import { auth } from '../config/firebase-config';
const API_URL = process.env.REACT_APP_BACKEND_PATH;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

api.interceptors.request.use(async (config) => {

  const user = auth.currentUser;

  if (user) {
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${ token }`;
  }
  return config;
});

export default api;