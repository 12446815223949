import { Row, Col, Container } from 'react-bootstrap';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import styles from '../styles/Footer.module.css'

export default function Footer() {
    return (
        <div className={`${styles.footerContainer}`}>
            <Container className='pt-40'>
            <Row className='my-3'><div className='bold fontM text-center mt-4'>Vuoi collaborare con Teseo? Scrivici!</div></Row>
            <Row className='my-3'><div className='bold fontS text-center'>teseotraduce@gmail.com</div></Row>
            <Row>
                <Col className='justify-content-start d-flex regular fontM'>
                    <a href="https://www.iubenda.com/privacy-policy/27118703" target="_blank" className='regular white m-2'>Privacy Policy</a>
                    <a href="https://www.iubenda.com/privacy-policy/27118703/cookie-policy" target="_blank" className='regular white m-2'>Cookie Policy</a>
                    <a href="https://www.iubenda.com/termini-e-condizioni/27118703" target="_blank" className='regular white m-2'>Termini e Condizioni</a>
                </Col>
                <Col className='justify-content-end d-flex align-items-center'>
                    <a href="https://www.instagram.com/teseo.app" className="socialTeseo" target="_blank">
                        <FaInstagram size={27} style={{ marginRight: '10px' }} />
                    </a>
                    <a href="https://www.tiktok.com/@teseo.app" className="socialTeseo" target="_blank">
                        <FaTiktok size={27} />
                    </a>
                </Col>
            </Row>
        </Container>
        </div>  
    );
}