import {Link, useParams} from 'react-router-dom';
import styles from "../styles/Checkout.module.css"
import GButton from '../components/GButton';
import { useUserAuth } from "../components/UserAuthContext";

import React, { useEffect } from 'react';

const CheckoutYes = () => {
    
    const { userData } = useUserAuth();

    return(
        <div className={`${styles.checkoutPage} d-flex align-items-center justify-content-center flex-column`}>
            <div className={styles.checkoutBox}>
                <h1 className='bold fontXXXL mb-1'>
                    Grazie per aver scelto Teseo!
                </h1>
            </div>
        </div>
    );
};

export default CheckoutYes;